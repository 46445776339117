import React, { useEffect, useRef, useState } from 'react';
import { UserStatus, ConnectionStatus } from './call-in-app/constants';
import { useParams, useLocation  } from "react-router-dom";
import App from './App'
import CallInAppService from './call-in-app/CallInAppService';
import PropTypes from "prop-types";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import {AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import {TextField, Button} from "@material-ui/core";
import {useSelector} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import {makeStyles} from "@material-ui/core/styles";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const cia = CallInAppService.getInstance();

const _savedConferenceProfile = '_savedConferenceProfile';
const _savedLoggedInUserKey = "_savedLoggedInUserKey";
const _savedUserInformation = '_saveUserInformation';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));


export default function Join(props) {
    const classes = useStyles();
    console.log("[Join screen] rendering");
    let { roomId } = useParams();
    let  roomPwd = useQuery().get('PWD');

    console.log ("Password:", roomPwd);

    // GUI Variables
    const [isLoading, setIsLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isShowUserInput, setIsShowUserInput] = useState(false);

    // Data Variables
    const [userInfo, setUserInfo] = useState(null);
    const [confProfile, setConfProfile] = useState(null);
    const [loggedProfile, setLoggedProfile] = useState(null);

    // state
    const { userStatus, connectionStatus } = useSelector( state => state.user);

    useEffect(() => {
        let savedUserInformation = localStorage.getItem(_savedUserInformation);
        if (!savedUserInformation) {
            setIsShowUserInput(true);
        } else {
            savedUserInformation = JSON.parse(savedUserInformation);
            setUserInfo(savedUserInformation);
        }
    }, [isShowUserInput]);

    useEffect(() => {
        localStorage.removeItem(_savedLoggedInUserKey);
        localStorage.removeItem(_savedConferenceProfile);
        // Call Conference Login API asynchronous
        setTimeout(() => {
            let password = uuidv4();
            let extension = password.slice(-12);
            let profile = {
                'roomId': roomId,
                'roomPwd': roomPwd,
                'confName': `conference ID-${roomId}`,
                'confDestination': `conf-${roomId}-cdquality--1`,
                // 'confDestination': '*9664',
                // "wssUrl":"ws://11.11.7.50:8081",
                // "extension":"111",
                // "password":"0123456789",
                // "domain":" pbx.callbot.io",

                // 'confDestination': '*1998',
                "wssUrl":"wss://callinapp.com:8082",
                // "extension":9000 + Math.floor(Math.random() * 19) + 1,
                // "password":"123456",
                // "domain":"hosp.callinapp.com",
                "extension":extension,
                "password":password,
                "domain":"conference.callinapp.com"
            };
            // profile.confDestination = "*1998";
            // profile.confName ="*1998";

            if (roomId == '1111') profile['confDestination'] = `conf-${roomId}-test--1`;
            if (roomId == 'local') {
                profile[ "wssUrl"] = "ws://11.11.7.50:8081";
                profile['domain']  = "conference.fctv.com";
                // profile['confDestination'] = `conf-${roomId}-test--1`;
                // profile["extension"] = "111";
                // profile["password"] = "0123456789";
            }

            localStorage.setItem(_savedConferenceProfile, JSON.stringify(profile))

            setConfProfile(profile);
        }, 5000)
    },[]);

    useEffect(() => {
        if(!confProfile || !userInfo)
            return;
        const userProfile = {
            wssUrl: confProfile['wssUrl'],
            extension: confProfile['extension'],
            domain: confProfile['domain'],
            // password: confProfile['password'],
            password: confProfile['extension'],
            callOptions: {
                callerIdName: userInfo['display'],
                callerIdNumber: confProfile['extension'] +'@' + confProfile['domain'],
            },
            userInfo: {
                display: userInfo['display'],
                email: userInfo['email'],
                userId: confProfile['extension'] +'@' + confProfile['domain']
            }
        };
        cia.login(userProfile);
        // ??? Need to wait for Login successfully
    },[confProfile, userInfo]);

    useEffect( () => {
        if(userStatus != UserStatus.LOGGED_IN) {
            setIsLoading( true);
            setIsLogged(false);
        } else {
            setIsLoading( false);
            setIsLogged(true);
        }
    }, [userStatus]);

    const handleDialogClose = () => {
        setIsShowUserInput(false);
    }

    return ( <div>
        <nav>
            <AppBar position="static" style={{color: 'rgb(66, 127, 225)', background: 'rgb(255,255,255', height: '80px'}}>
                <Toolbar>

                    <Typography variant="h6" className={classes.title}>
                        Callinapp
                    </Typography>
                    <Button color="inherit">Language</Button> |
                    <Button color="inherit">Setting</Button>
                </Toolbar>
            </AppBar>
        </nav>
        <div style={{height: '10px' }} />

        {isShowUserInput ? <UserInformationDialog onClose={handleDialogClose} />: null}
        { isLoading ? <div> Authenticating.... {connectionStatus} : {userStatus}</div> : null}
        { isLogged ? <App/> : null }
    </div> )
};

function UserInformationDialog (props) {
    const {onClose} = props;
    const [display, setDisplay] = useState('');
    const [email, setEmail] = useState('');

    useEffect( () => {
        let savedUserInformation = localStorage.getItem(_savedUserInformation);
        if(savedUserInformation) {
            savedUserInformation = JSON.parse(savedUserInformation);
            setDisplay(savedUserInformation['display'] || '');
            setEmail(savedUserInformation['email'] || '');
        }
    }, []);

    const handleSave = () => {
        localStorage.setItem(_savedUserInformation, JSON.stringify({display, email}));
        onClose();
    };
    return (
        <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Provide</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To join to this conference, please enter your displaying name and email address here.
                    They will let other participants know about you.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Displaying Name"
                    type="name"
                    value={display}
                    onChange={ e => setDisplay(e.target.value)}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    required
                    id="name"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={ e => setEmail(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary" disabled={display == '' || email == ''}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
UserInformationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};