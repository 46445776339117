import {
    JOIN_CONFERENCE_MEMBER, UPDATE_CONFERENCE_MEMBER, LEFT_CONFERENCE_MEMBER, CLEAR_CONFERENCE_MEMBER,
    CONFERENCE_START_SHARESCREEN, CONFERENCE_STOP_SHARESCREEN, JOIN_CONFERENCE, LEAVE_CONFERENCE
} from '../actions/actionTypes';

const defaultState = {
    conferenceState: null,
    updatedMembers: [],
    sharingMember: null,
    isSharing: false,
    youAreSharingScreen: false
};

const _savedLoggedInUserKey = "_savedLoggedInUserKey";

export default (state = defaultState, action) => {
    switch (action.type) {
        case JOIN_CONFERENCE: {
            console.log("[conferenceReducer] JOIN_CONFERENCE");
            console.log("Members:",action.payload.members);

            let isSharingScreen = action.payload.members.find(member =>  member['media']['video']['screenShare'] === true)
            console.log("isSharingScreen:", isSharingScreen);

            return {
                ...state,
                isSharing: isSharingScreen ? true : false,
                sharingMember: isSharingScreen
            }
        }
        case LEAVE_CONFERENCE: {
            console.log("[conferenceReducer] LEFT_CONFERENCE");
            return {
                ...state,
                isSharing: false,
                youAreSharingScreen: false,
                sharingMember: null

            }
        }
        case JOIN_CONFERENCE_MEMBER: {
            console.log("[conferenceReducer] JOIN_CONFERENCE_MEMBER");
            return {
                ...state,
                updatedMembers: action.payload.members,
                conferenceState: JOIN_CONFERENCE_MEMBER
            }
        }
        case LEFT_CONFERENCE_MEMBER: {
            console.log("[conferenceReducer] LEFT_CONFERENCE_MEMBER");
            return {
                ...state,
                updatedMembers: action.payload.members,
                conferenceState: LEFT_CONFERENCE_MEMBER
            }
        }
        case UPDATE_CONFERENCE_MEMBER: {
          // console.log("[conferenceReducer] UPDATE_CONFERENCE_MEMBER");
          return {
            ...state,
            updatedMembers: action.payload.members,
            conferenceState: UPDATE_CONFERENCE_MEMBER
          }
        }
        case CLEAR_CONFERENCE_MEMBER: {
          console.log("[conferenceReducer] CLEAR_CONFERENCE_MEMBER");
          return {
            ...state,
            updatedMembers: action.payload.members,
            conferenceState: CLEAR_CONFERENCE_MEMBER
          }
        }
        case CONFERENCE_START_SHARESCREEN: {
            console.log("[conferenceReducer] CONFERENCE_START_SHARESCREEN");
            let savedLoggedInUserKey = localStorage.getItem(_savedLoggedInUserKey);
            let isYouAreSharing = false;

            if(savedLoggedInUserKey) {
                savedLoggedInUserKey = JSON.parse(savedLoggedInUserKey);
                let { callerIdName } = action.payload.sharingMember;
                let savedCallerIdName = savedLoggedInUserKey['extension'];
                if (callerIdName == savedCallerIdName) isYouAreSharing = true;
            }
            return {
                ...state,
                sharingMember: action.payload.sharingMember,
                isSharing: true,
                youAreSharingScreen: isYouAreSharing

            }
        }
        case CONFERENCE_STOP_SHARESCREEN: {
            console.log("[conferenceReducer] CONFERENCE_STOP_SHARESCREEN");
            return {
                ...state,
                sharingMember: action.payload.sharingMember,
                isSharing: false,
                youAreSharingScreen: false,  //Only One can sharing screen at a time
                updatedMembers: action.payload.members
            }
        }
        default:
            return state;

    }
}
