import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import PropTypes from "prop-types";


const _savedUserInformation = '_saveUserInformation';
export default function UserInformationDialog (props) {
    const {onClose} = props;
    const [display, setDisplay] = useState('');
    const [email, setEmail] = useState('');

    useEffect( () => {
        let savedUserInformation = localStorage.getItem(_savedUserInformation);
        if(savedUserInformation) {
            savedUserInformation = JSON.parse(savedUserInformation);
            setDisplay(savedUserInformation['display'] || '');
            setEmail(savedUserInformation['email'] || '');
        }
    }, []);

    const handleSave = () => {
        localStorage.setItem(_savedUserInformation, JSON.stringify({display, email}));
        onClose();
    };
    return (
        <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Provide</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To join to this conference, please enter your displaying name and email address here.
                    They will let other participants know about you.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Displaying Name"
                    type="name"
                    value={display}
                    onChange={ e => setDisplay(e.target.value)}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    required
                    id="name"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={ e => setEmail(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary" disabled={display == '' || email == ''}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
UserInformationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};