import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation  } from "react-router-dom";
import CallInAppService from '../call-in-app/CallInAppService';
import {AppBar, Paper, Toolbar, IconButton, Typography, Button, Grid, Box, Link} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";

import { Loading } from '../views'
import InConference from "../inconference";

import UserInformationDialog from "./UserInformationDialog"
import {v4 as uuidv4} from "uuid";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const cia = CallInAppService.getInstance();

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
}));

const _savedConferenceProfile = '_savedConferenceProfile';
const _savedLoggedInUserKey = "_savedLoggedInUserKey";
const _savedUserInformation = '_saveUserInformation';

export default function Join(props) {
    const classes = useStyles();

    console.log("[Join screen] rendering");
    let { roomId } = useParams();
    let  roomPwd = useQuery().get('PWD');

    const [loading, setLoading] = useState(false);
    const [joined, setJoined] = useState(false);
    const [showUserInput, setShowUserInput] = useState(false);
    const [conferenceProfile, setConferenceProfile] = useState(null);

    const handleDialogClose = () => {
        setShowUserInput(false);
    }
    const handleJoinButton = () => {
        setJoined(true);
    }

    useEffect(() => {
        let savedUserInformation = localStorage.getItem(_savedUserInformation);
        if (!savedUserInformation) {
            setShowUserInput(true);
        }
    }, [showUserInput]);

    useEffect(() => {
        setLoading(true);

        localStorage.removeItem(_savedLoggedInUserKey);
        localStorage.removeItem(_savedConferenceProfile);

        // Call Conference Login API asynchronous
        setTimeout(() => {
            let password = uuidv4();
            let extension = password.slice(-12);
            let profile = {
                'roomId': roomId,
                'roomPwd': roomPwd,
                'confName': `conference ID-${roomId}`,
                'confDestination': `conf-${roomId}-cdquality--1`,
                // 'confDestination': '*9664',
                // "wssUrl":"ws://11.11.7.50:8081",
                // "extension":"111",
                // "password":"0123456789",
                // "domain":" pbx.callbot.io",

                // 'confDestination': '*1998',
                "wssUrl":"wss://callinapp.com:8082",
                // "extension":9000 + Math.floor(Math.random() * 19) + 1,
                // "password":"123456",
                // "domain":"hosp.callinapp.com",
                "extension":extension,
                "password":password,
                "domain":"conference.callinapp.com"
            };
            // profile.confDestination = "*1998";
            // profile.confName ="*1998";

            if (roomId == '1111') profile['confDestination'] = `conf-${roomId}-test--1`;
            if (roomId == 'local') {
                profile[ "wssUrl"] = "ws://11.11.7.50:8081";
                profile['domain']  = "conference.fctv.com";
                // profile['confDestination'] = `conf-${roomId}-test--1`;
                // profile["extension"] = "111";
                // profile["password"] = "0123456789";
            }
            localStorage.setItem(_savedConferenceProfile, JSON.stringify(profile))
            setConferenceProfile(profile);
            setLoading(false);
        }, 1000)
    },[]);

    return (
    <div>
        { loading ? <Loading open={true}/> : null }
        { showUserInput ? <UserInformationDialog onClose={handleDialogClose} />: null }
        { joined ? <InConference/> : null }
        { !joined && conferenceProfile ?
            <div>
                { isBrowser || isTablet ?
                    <div>
                        <nav>
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.title}>
                                        Callinapp
                                    </Typography>
                                    <Button color="inherit" size="large">
                                        Support
                                    </Button> |
                                    <Button color="inherit" size="large">
                                        English
                                    </Button>
                                </Toolbar>
                            </AppBar>
                        </nav>
                        <Paper elevation={0}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '5em'}}></div>
                                <Typography variant="h4" style={{padding: '1em'}}>Your meeting has been started</Typography>
                                <Button variant="contained" color='primary' onClick={handleJoinButton}> Join Now </Button>
                                <div style={{height: '2em'}}></div>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} variant="outlined" square>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '3em'}}></div>
                                <div>
                                    <Typography variant="h5" color="primary" style={{padding: '1em'}}>CONFERENCE NAME</Typography>
                                    <Box fontWeight="fontWeightLight">
                                        {`Hosted by: `}
                                        <Typography variant="body2" style={{display: 'contents', padding: '1em'}}>HOSTED BY</Typography>
                                    </Box>
                                    <Box fontWeight="fontWeightLight">
                                        {`Date/Time: `}
                                        <Typography variant="body2" style={{display: 'contents',padding: '1em'}}>DATETIME</Typography>
                                    </Box>
                                </div>
                                <Typography variant="body2" style={{ textAlign:'center', margin: '2em'}}>
                                    "Apple marketing members will discuss about Innovative Marketing Campaign for next Q4, 2020"
                                </Typography>
                                <div style={{height: '0.5em'}}></div>
                            </Grid>
                        </Paper>
                        <Paper elevation={0}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '2em'}}></div>
                                <Typography variant='body2' component="div">
                                    If you are the conference host,
                                </Typography>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        console.info("I'm going to login as a Host.");
                                    }}
                                >
                                    Host Login?
                                </Link>
                            </Grid>
                        </Paper>
                        <Paper elevation={0}>
                            <div style={{height: '5em'}}></div>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Box fontWeight="fontWeightLight" textAlign="center" fontSize={11}>
                                    Copyright @2020 Callinapp Video Conference, Inc. All rights reserved.
                                </Box>
                                <Box fontWeight="fontWeightLight" textAlign="center" fontSize={11}>
                                    Privacy & Legal Policies
                                </Box>
                            </Grid>
                        </Paper>
                    </div>
                    :
                    <div style={{touchAction: "pan-x pan-y"}}>
                        <nav>
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.title}>
                                        Callinapp
                                    </Typography>
                                    <Button color="inherit" size="small">
                                        Support
                                    </Button> |
                                    <Button color="inherit" size="small">
                                        English
                                    </Button>
                                </Toolbar>
                            </AppBar>
                        </nav>
                        <Paper elevation={0}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '2em'}}></div>
                                <Typography variant="h5" style={{padding: '1em'}}>Your meeting has been started</Typography>
                                <Button variant="contained" color='primary' onClick={handleJoinButton}> Join Now </Button>
                                <div style={{height: '2em'}}></div>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} variant="outlined" square>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '0.5em'}}></div>
                                <div>
                                    <Typography variant="h5" color="primary" style={{padding: '1em'}}>CONFERENCE NAME</Typography>
                                    <Box fontWeight="fontWeightLight">
                                        {`Hosted by: `}
                                        <Typography variant="body2" style={{display: 'contents', padding: '1em'}}>HOSTED BY</Typography>
                                    </Box>
                                    <Box fontWeight="fontWeightLight">
                                        {`Date/Time: `}
                                        <Typography variant="body2" style={{display: 'contents',padding: '1em'}}>DATETIME</Typography>
                                    </Box>
                                </div>
                                <Typography variant="body2" style={{ textAlign:'center', margin: '2em'}}>
                                    "Apple marketing members will discuss about Innovative Marketing Campaign for next Q4, 2020"
                                </Typography>
                                <div style={{height: '1em'}}></div>
                            </Grid>
                        </Paper>
                        <Paper elevation={0}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{height: '1em'}}></div>
                                <Typography variant='body2' component="div">
                                    If you are the conference host,
                                </Typography>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        console.info("I'm going to login as a Host.");
                                    }}
                                >
                                    Host Login?
                                </Link>
                            </Grid>
                        </Paper>
                        <Paper elevation={0}>
                            <div style={{height: '5em'}}></div>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Box fontWeight="fontWeightLight" textAlign="center" fontSize={11}>
                                    Copyright @2020 Callinapp Video Conference, Inc. All rights reserved.
                                </Box>
                                <Box fontWeight="fontWeightLight" textAlign="center" fontSize={11}>
                                    Privacy & Legal Policies
                                </Box>
                            </Grid>
                        </Paper>
                    </div>
                }
            </div>
            : null
        }
    </div> )
};
