import React, {useCallback, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {useSelector} from "react-redux";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import Login from './Login';
// import Dialpad from './Dialpad';
import Launch from './Launch';
import InCall from './InCall';
import Participants from './Participants';
import Chat from './Chat';

import CallInAppService from './call-in-app/CallInAppService';


const cia = CallInAppService.getInstance();

const drawerWidth = 360;
const maxWidth = 1028;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(${maxWidth}px)`,
    display: 'flex',
    position: 'relative',
    // minHeight:  `calc(${maxWidth}*0.5px)`,
    // maxHeight: `calc(${maxWidth}*0.65px)`,
    overflowY: 'hidden',
    overflowX: 'hidden',
    padding: 0
  },
  appBar: {
    // maxWidth: `calc(${maxWidth}px - 48px)`,
    // right: 'unset',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: 'inherit'
  },
  drawerPaper: {
    // position: 'absolute',
    // right: 'unset',
    width: drawerWidth,
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));


const _savedConferenceProfile = '_savedConferenceProfile';


export default function App() {
  console.log('[APP Screen] Rendering....');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(isBrowser);

  const [confName, setConfName] = React.useState('Callinapp Conference');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const ciaUser = useSelector(state => state.user || {});
  const ciaCall = useSelector(state => state.call || {});

  const login = useCallback((user) => {
    console.log("I an im App Login");
    cia.login(user);
    console.log(user);
  }, []);

  const logout = useCallback(() => {
    cia.logout();
  }, []);

  const handleLogin = (user) => {
    logout();
    login(user);
  };

  const handleMakeCall = (destinationNumber, isVideoConference = false) => {
    cia.makeCall(destinationNumber, isVideoConference);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    // Ignore if user has logged in
    if (cia.isLoggedIn) {
      console.log('User has logged in');
      return;
    }

    cia.user && login(cia.user);
  }, [login]);

  useEffect( () => {
    let savedConferenceProfile = localStorage.getItem(_savedConferenceProfile);
    savedConferenceProfile = JSON.parse(savedConferenceProfile);

    let confName = savedConferenceProfile['confName'];
    setConfName(confName);
  },[])

  return (
      <Container className={classes.root}>
        <CssBaseline />
        <AppBar
            position="absolute"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              {confName}
            </Typography>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
        >
          <div className={classes.drawerHeader} />
          {/*{ !ciaUser.user ? <Login handleLogin={handleLogin} user={cia.user}/> : null }*/}
          {/*{ (ciaUser.user && !ciaCall.call) ? <Dialpad handleMakeCall={handleMakeCall}/> : null }*/}
          { (ciaUser.user && !ciaCall.call) ? <Launch handleMakeCall={handleMakeCall}/> : null }
          { ciaCall.call ? <InCall call={ciaCall.call} /> : null }

        </main>
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <Participants/>
          <Divider />
          <Chat/>
        </Drawer>
      </Container>
  );
}
