import React, { useEffect, useRef, useState } from 'react';
import {AppBar, Paper, Toolbar, IconButton, Typography, Badge, Grid, Button, Dialog} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import {makeStyles} from "@material-ui/core/styles";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { AvatarGroup } from "@material-ui/lab"
import { Avatar } from "@material-ui/core"
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Loading } from '../views'
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/MoreVert';
import ChatIcon from '@material-ui/icons/Chat';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import CloseIcon from '@material-ui/icons/Close';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';

import clsx from "clsx";

import CallInAppService from '../call-in-app/CallInAppService';

import Chat from "../chat/index"

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -80,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    fabAudio: {
        left: -120,
        color: "#1976d2",
        border: "1px solid rgba(25, 118, 210, 0.5)",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    fabVideo: {
        right: -120,
        color: "#1976d2",
        border: "1px solid rgba(25, 118, 210, 0.5)",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    fabZoom: {
        margin: '0',
        left: "unset",
        right: 20,
        color: "#1976d2",
        border: "1px solid rgba(25, 118, 210, 0.5)",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    videoContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width:"100%", height:"100%",
        overflowX:"scroll",
        // scrollSnapType:"both mandatory",
        display: "flex",
        justifyContent: "center"
    },
    videoContainer1: {
        position: "fixed",
        right: 0,
        bottom: 0,
        minWidth: "100%",
        minHeight: "100%"
    },
    dialogPaper: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },

    talking: {
        '&::after': {
            position: 'absolute',
            top: "-15px",
            left: "-15px",
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: `$ripple 3s infinite ${theme.transitions.easing.easeOut}`,
            border: '15px solid currentColor',
            content: '""',
        },
    },

    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.2)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 0,
        },
    },

    small: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    root: {
        display: 'flex',
        transition: "transform 0.5s",
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    animated: {
        // transition: "transform 2s",
        transform: "translate3d(30px,-80px,0) scale(.8)",
    }
}));

export default function MobileConference (props) {
    const classes = useStyles();
    const remoteVideoElementRef = useRef();







    /* Handle ChatDialog */
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const [openChat, setOpenChat] = React.useState(false);
    const [videoZoom, setVideoZoom] = React.useState(false);

    const handleOpenChat = () => {
        console.log("Opening Chat Dialog");
        setOpenChat(true);
    };

    const handleCloseChat = () => {
        setOpenChat(false);
    };

    const toggleVideoZoom = () => {
        // Does not work on iOS Safari Mobile browser
        // console.log("remote Video:", remoteVideoElementRef.current);
        // let element = remoteVideoElementRef.current;
        // if (screenfull.isEnabled) {
        //     screenfull.request(element);
        // }

        // let element = remoteVideoElementRef.current;
        // console.log("Video Element:", element);
        // element.scrollLeft = "100"

        let nextVideoZoom = !videoZoom;
        setVideoZoom(nextVideoZoom)
    }

    const [orientation, setOrientation] = useState(null);

    useEffect( () => {
        // Work on Chrome browser but not iOS Safari
        // let screen = window.screen;
        // let orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
        // let type = orientation.includes('portrait') === true ? "portrait": "landscape";
        // console.log("the orientation of the device is now " + type);
        // setOrientation(type);
        //
        // window.addEventListener("orientationchange", function(event) {
        //     let screen = window.screen;
        //     let orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
        //     let type = orientation.includes('portrait') === true ? "portrait": "landscape";
        //     console.log("the orientation of the device is now " + type);
        //     setOrientation(type);
        // });

        // These codes works on iOS Safari mobile
        let mql = window.matchMedia("(orientation: portrait)");
        // If there are matches, we're in portrait
        if(mql.matches) {
            setOrientation('portrait');
        } else {
            // Landscape orientation
            setOrientation("landscape");
        }
        window.addEventListener("orientationchange", function(event) {
            let mql = window.matchMedia("(orientation: portrait)");
            // If there are matches, we're in portrait
            if(mql.matches) {
                console.log("set to landscape");
                setOrientation('landscape');
                setVideoZoom(false); // force not Zoom
            } else {
                // Landscape orientation
                console.log("set to portrait");
                setOrientation("portrait");
            }
        });
   }, []);


    return (
        <div style={{overflowX:"hidden"}}>
            <div className={classes.videoContainer}
                 // style={orientation == "portrait" && !videoZoom ? { top: "30%" } : null }
            >
                <video width={videoZoom ? "1280px": "100%"} height="auto"
                       style={videoZoom ? {objectFit:"cover"}: null}
                       loop={true}
                       autoPlay={true}
                       playsinline
                       ref={remoteVideoElementRef}
                >
                    <source src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4"
                            type="video/mp4"/>
                </video>
            </div>
        <DeviceOrientation lockOrientation={'landscape'}>
            {/* Will only be in DOM in landscape */}
            <Orientation orientation='landscape' alwaysRender={false}>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Fab color="secondary" aria-label="hangup" className={classes.fabButton}>
                            <CallEndIcon />
                        </Fab>
                        <Fab color="primary" size="medium" aria-label="audio" className={clsx(classes.fabButton, classes.fabAudio)}>
                            <MicIcon />
                        </Fab>
                        <Fab color="primary" size="medium" aria-label="video" className={clsx(classes.fabButton, classes.fabVideo)}>
                            <VideocamIcon />
                        </Fab>
                        {/*<Fab color="primary" size="medium" aria-label="video" className={clsx(classes.fabButton, classes.fabZoom)}*/}
                        {/*     onClick={toggleVideoZoom}*/}
                        {/*>*/}
                        {/*    <ZoomOutMapIcon />*/}
                        {/*</Fab>*/}
                </AppBar>
            </Orientation>
            {/* Will stay in DOM, but is only visible in portrait */}
            <Orientation orientation='portrait' alwaysRender={false}>
                    {/*<CssBaseline />*/}
                    <Paper square className={classes.paper}>
                         <Typography className={classes.text} variant="h5" gutterBottom>
                                In Conference
                         </Typography>
                    </Paper>
                    <AvatarGroup max={4}
                                 spacing ={"medium"}
                                 classes ={{root: clsx(classes.root,{[classes.animated]: videoZoom}), avatar: clsx(classes.small)}}
                                 style={{float: "right", padding: "1.5em"}}>
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            style={{border:"unset"}}
                            badgeContent={<IconButton variant="outlined" color="green">
                                        <MicNoneOutlinedIcon/>
                                        </IconButton>}
                        >
                            <Avatar alt="Remy Sharp"
                                    style={{width: "100%", height:"100%", border:"none"}}
                                    className={clsx(classes.orange, classes.talking)}
                                    src="/static/images/avatar/1.jpg" />
                        </Badge>
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            style={{border:"unset"}}
                            badgeContent={<IconButton variant="outlined" color="green">
                                <MicNoneOutlinedIcon/>
                            </IconButton>}
                        >
                            <Avatar alt="Remy Sharp"
                                    style={{width: "100%", height:"100%", border:"none"}}
                                    className={clsx(classes.orange, classes.talking)}
                                    src="/static/images/avatar/1.jpg" />
                        </Badge>

                        <Avatar alt="Travis Howard"
                                className={clsx(classes.orange, classes.talking)}
                                src="/static/images/avatar/2.jpg" >
                         </Avatar>
                        <Avatar alt="Cindy Baker"
                                className={clsx(classes.orange)}
                                src="/static/images/avatar/3.jpg" />
                        <Avatar alt="Agnes Walker"
                                className={clsx(classes.orange, classes.talking)}
                                src="/static/images/avatar/4.jpg" />
                        <Avatar alt="Trevor Henderson"
                                className={clsx(classes.orange)}
                                src="/static/images/avatar/5.jpg" />
                    </AvatarGroup>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" aria-label="open modal" onClick={handleOpenChat} >
                                <ChatIcon />
                            </IconButton>
                            <Fab color="secondary" aria-label="hangup" className={classes.fabButton}>
                                <CallEndIcon />
                            </Fab>
                            <Fab color="primary" size="medium" aria-label="audio" className={clsx(classes.fabButton, classes.fabAudio)}>
                                <MicIcon />
                            </Fab>
                            <Fab color="primary" size="medium" aria-label="video" className={clsx(classes.fabButton, classes.fabVideo)}>
                                <VideocamIcon />
                            </Fab>
                            <Fab color="primary" size="medium" aria-label="video" className={clsx(classes.fabButton, classes.fabZoom)}
                                 onClick={toggleVideoZoom}>
                                <ZoomOutMapIcon />
                            </Fab>
                            <div className={classes.grow} />
                            <Badge badgeContent={4} color="secondary">
                                <PeopleAltIcon />
                            </Badge>
                            <IconButton edge="end" color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {<ChatDialog/>}
            </Orientation>
        </DeviceOrientation>
        </div>
    )

    function ChatDialog() {
        return (
            <div style={{overflowX:"hidden"}}>
                <Dialog fullScreen open={openChat} onClose={handleCloseChat} TransitionComponent={Transition}
                    classes={{paper: classes.dialogPaper}}>
                    <AppBar >
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseChat} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                CHAT MESSAGES
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {<Chat></Chat>}
                </Dialog>
            </div>
        )
    }

};