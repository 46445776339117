import {
  JOIN_CONFERENCE,  LEAVE_CONFERENCE,
  CONFERENCE_CHAT_MESSAGE,
  JOIN_CONFERENCE_MEMBER, UPDATE_CONFERENCE_MEMBER, LEFT_CONFERENCE_MEMBER, CLEAR_CONFERENCE_MEMBER,
} from './call-in-app/actions/actionTypes';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {Button, IconButton, Card, Container, Grid, TextField, Typography, Popover} from '@material-ui/core';
import {CallEnd } from '@material-ui/icons';
import {MicOff, Mic } from '@material-ui/icons';
import {VideocamOff, Videocam } from '@material-ui/icons';
import {Pause, PlayArrow } from '@material-ui/icons';
import { ScreenShare as ScreenShareIcon, StopScreenShare as StopScreenShareIcon} from '@material-ui/icons';
import {FullscreenExit, Fullscreen } from "@material-ui/icons";
import {ExitToAppSharp } from '@material-ui/icons';


import { useSelector } from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";

const footerHeight = 60;
const iconSize = 40;
const maxAvatarWidth = 100;

const useStyles = makeStyles({
  root: {
    // height: `calc(100% - 40px)`,
    width: "inherit",
  },
  gridVideo: {
    width: "100%",
    height: `calc(100% - ${footerHeight}px)`,
    position: "relative",
    background: "#eceff1"
  },
  gridButtons: {
    width: "100%",
    height: `calc(${footerHeight}px)`,
    background: "rgb(244, 244, 244)"
  },
  fullGridButtons: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10001
  },
  textField: {
    width: '100%',
  },
  remoteVideo: {
    width: '100%',
    height: `calc(100% - 0px)`,
    // objectFit: 'cover',
    objectFit: 'contain',
    background: '#eceff1'
  },
  fullRemoteVideo: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    objectFit: 'cover'
  },
  shareScreen: {
    position:'absolute',
    top:'10%',
    bottom:'10%',
    left:'5%',
    right:'5%',
    background:'rgba(63, 81, 181, 80%)',
    display:'flex',
    alignItems:'center'
  },
  fullShareScreen: {
    position: 'fixed',
    top:'1%',
    bottom:'5%',
    left:'1%',
    right:'1%',
    zIndex: 11000,
  },
  localVideo: {
    position: "absolute",
    right: "10px",
    bottom: "10px",
    width: `calc(${maxAvatarWidth}px)`,
    height: `calc(${maxAvatarWidth}px)`,
    objectFit: 'cover',
    // objectFit: 'contain'
  },
  button: {
    marginTop: '1em'
  }
});


export default function InCall(props) {
  console.log('[INCALL SCREEN] rendering...');
  const classes = useStyles();
  const localVideoElementRef = useRef();
  const remoteVideoElementRef = useRef();
  const { call } = props;
  const { localStream, remoteStream, timeElapsed, conference, conferenceState, conferenceMembers } = useSelector(state => state.call);
  const {isSharing = true, youAreSharingScreen, sharingMember } = useSelector(state => state.conference);
  const [ dtmfDigits, setDtmfDigits ] = useState('');
  const [ message, setMessage ] = useState('');
  const [fullscreen, setFullscreen] = useState(false);
  // const [youAreSharing, setYouAreSharing] = useState(false);
  // const [haveASharing, setHaveASharing] = useState(false);

  const [useAudio, setUseAudio] = useState(false);
  const [useVideo, setUseVideo] = useState(false);
  const [micMute, setMicMute] = useState(false);
  const [camMute, setCamMute] = useState(false);
  const [callHold, setCallHold] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onHangup = () => {
    call && call.hangup();
  };

  const onToggleMuteMic = () => {
    if (call) {
      let micMuted = call.toggleMuteMic();
      console.log('Mic muted:', micMuted);
      setMicMute(micMuted);
    }
  }

  const onToggleMuteCam = () => {
    if (call) {
      let camMuted = call.toggleMuteCam();
      console.log('Cam muted:', camMuted);
      setCamMute(camMuted)
    }
  }

  const onToggleHold = () => {
    if (call) {
      call.toggleHold();
      let callState = !callHold;
      console.log("CalHold:", callState);
      setCallHold(callState);
    }
  }

  const onToggleFullscreen = () => {
    if (call) {
      console.log("Set Fullscreen");
      setFullscreen(!fullscreen);
    }
  }

  const sendDtmf = () => {
    if (call && dtmfDigits) {
      call.sendDtmf(dtmfDigits);
    }
  }

  const sendMessage = () => {
    if (conference && message) {
      conference.sendChat(message);
    }
  }

  const startScreenShare = () => {
    if (call) {
      call.startScreenShare();
    }
  }

  const stopScreenShare = () => {
    if (call) {
      call.stopScreenShare();
    }
  }

  const onRemoteVideoSizeChanged = (event) => {
    console.log("Video Height:", event.target.videoHeight)
    console.log("Video Width:", event.target.videoWidth)
    console.log("Video Width:", event.target.height)
    console.log("Video Width:", event.target.width)
  }

  useEffect(() => {
    localVideoElementRef.current && (localVideoElementRef.current.srcObject = localStream);
  },[localVideoElementRef, localStream]);

  useEffect(() => {
    remoteVideoElementRef.current && (remoteVideoElementRef.current.srcObject = remoteStream);
  },[remoteVideoElementRef, remoteStream]);

  useEffect(() => {
    let useVideo = call.options.useVideo != false ? true : false;
    let useAudio = call.options.useAudio != false ? true : false;
    setUseVideo(useVideo);
    setUseAudio(useAudio);
  },[]);

  useEffect( () => {
    if(conferenceState === JOIN_CONFERENCE) {
       let filteredMe = conferenceMembers.filter(member => member.me === true);
          if(filteredMe) {
            filteredMe = filteredMe[0];
            setMicMute(filteredMe['media']['audio']['muted']);
            setCamMute(filteredMe['media']['video']['muted']);
            // setCallHold(filteredMe[0]['media']['audio']['muted']);
          }
    }
  }, [conferenceState]);

  useEffect( () => {
    if(sharingMember){
      console.log("Is Sharing screen???", isSharing) ;
      console.log("Are you sharing screen???", youAreSharingScreen)
    }
    // if(conference && conference.role == 'moderator') {
    //   if (isSharing) {
    //     console.log("Set sharing layout");
    //     conference.setVideoLayout('sharing');
    //   } else {
    //     console.log("Set sharing layout");
    //     conference.setVideoLayout('group:screen-sharing-and-members');
    //   }
    // }
  },[isSharing,sharingMember, youAreSharingScreen])

  return (
    <Grid container
          direction="column"
          justify="flex-end"
          alignItems="center" className={classes.root}>
      <Grid item className={classes.gridVideo}>
           <video className={clsx(classes.remoteVideo, {[classes.fullRemoteVideo]: fullscreen})}
                  autoPlay={true} playsInline
                  ref={remoteVideoElementRef}
                  // poster="/logo192.png"
                   // onLoadedMetadata={onRemoteVideoSizeChanged}
                   // onresize={onRemoteVideoSizeChanged}
           >
           </video>
        { youAreSharingScreen ?
            <div className={clsx(classes.shareScreen, {[classes.fullShareScreen]: fullscreen})}>
               <span style={{margin:'auto'}}>
                  <Button variant="contained" color="secondary" onClick={stopScreenShare}>Stop sharing</Button>
               </span>
            </div>
            : null
        }
           {/*<video hidden={!!conference}  className={classes.localVideo} autoPlay={true} playsInline ref={localVideoElementRef} muted={true}/>*/}
      </Grid>

      { fullscreen ?
          <Grid item container justify="center" alignItems="center" className={clsx(classes.gridButtons, classes.fullGridButtons)}>
            { isBrowser ?
                <Grid>
                  <Button disabled={isSharing} variant="outlined" color="primary"
                          startIcon={<ScreenShareIcon />}
                          onClick={startScreenShare}>
                    Share Screen
                  </Button>
                  {/*}*/}
                </Grid>
                : null
            }
            <Grid item>
                <BrowserView>
                  <Button disabled={useAudio === false} variant="outlined" color="primary"
                          startIcon={!micMute ? <Mic /> : < MicOff/> }
                          onClick={onToggleMuteMic}>
                    Toggle Mic
                  </Button>
                </BrowserView>
                <MobileView>
                  <IconButton disabled={useAudio === false} color="primary" aria-label="mute mic" component="span" onClick={onToggleMuteMic}>
                    { !micMute ? <Mic style={{ fontSize: iconSize }} /> : < MicOff style={{ fontSize: iconSize }} /> }
                  </IconButton>
                </MobileView>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button disabled={useVideo === false} variant="outlined" color="primary"
                          startIcon={ !camMute ? <Videocam /> : < VideocamOff/> }
                          onClick={onToggleMuteCam}>
                    Toggle Cam
                  </Button>
                </BrowserView>
                <MobileView>
                  <IconButton disabled={useVideo === false} color="primary" aria-label="mute camemra" component="span" onClick={onToggleMuteCam}>
                    { !camMute ? <Videocam style={{ fontSize: iconSize }} /> : < VideocamOff style={{ fontSize: iconSize }} /> }
                  </IconButton>
                </MobileView>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button variant="outlined" color="primary"
                          startIcon={ !callHold  ? <PlayArrow /> : < Pause/> }
                          onClick={onToggleHold}>
                    Toggle Hold
                  </Button>
                </BrowserView>
                <MobileView>
                  <IconButton color="primary" aria-label="hold call" component="span" onClick={onToggleHold}>
                    { !callHold ? <PlayArrow style={{ fontSize: iconSize }} /> : < Pause style={{ fontSize: iconSize }} /> }
                  </IconButton>
                </MobileView>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button variant="outlined" color="primary" onClick={onToggleFullscreen}>Exit Fullscreen</Button>
                </BrowserView>
                <MobileView>
                  <IconButton color="primary" aria-label="full screen" component="span" onClick={onToggleFullscreen}>
                    { !fullscreen ? <Fullscreen style={{ fontSize: iconSize }} /> : < FullscreenExit style={{ fontSize: iconSize }}/> }
                  </IconButton>
                </MobileView>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button variant="outlined" color="secondary"
                          startIcon={<ExitToAppSharp/>}
                          onClick={onHangup}>
                    Leave Meeting
                  </Button>
                </BrowserView>
                <MobileView>
                  <IconButton color="secondary" aria-label="hang up" component="span" onClick={onHangup}>
                    <ExitToAppSharp style={{ fontSize: iconSize }} />
                  </IconButton>
                </MobileView>
              </Grid>
          </Grid>
          :
          <Grid item container justify="center" alignItems="center" className={clsx(classes.gridButtons)}>
            { isBrowser ?
                <Grid>
                  {/*{ youAreSharingScreen ?*/}
                  {/*    <IconButton color="primary" aria-label="stop sharing" component="span" onClick={stopScreenShare}>*/}
                  {/*     < StopScreenShareIcon style={{ fontSize: iconSize }} />*/}
                  {/*    </IconButton>*/}
                  {/*    :*/}
                      <IconButton disabled={isSharing} color="primary" aria-label="star sharing" component="span" onClick={startScreenShare}>
                        < ScreenShareIcon style={{ fontSize: iconSize }} />
                      </IconButton>
                  {/*}*/}
                </Grid>
              : null
            }
            <Grid item>
              <IconButton disabled={useAudio === false} color="primary" aria-label="mute mic" component="span" onClick={onToggleMuteMic}>
                { !micMute ? <Mic style={{ fontSize: iconSize }} /> : < MicOff style={{ fontSize: iconSize }} /> }
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton disabled={useVideo === false} color="primary" aria-label="mute camemra" component="span" onClick={onToggleMuteCam}>
                { !camMute ? <Videocam style={{ fontSize: iconSize }} /> : < VideocamOff style={{ fontSize: iconSize }} /> }
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" aria-label="hold call" component="span" onClick={onToggleHold}>
                { !callHold ? <PlayArrow style={{ fontSize: iconSize }} /> : < Pause style={{ fontSize: iconSize }} /> }
              </IconButton>
             </Grid>
            <Grid item>
              <IconButton color="primary" aria-label="full screen" component="span" onClick={onToggleFullscreen}>
                { !fullscreen ? <Fullscreen style={{ fontSize: iconSize }} /> : < FullscreenExit style={{ fontSize: iconSize }}/> }
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="secondary"  aria-label="hang up" component="span" onClick={handlePopoverClick}>
                <ExitToAppSharp style={{ fontSize: iconSize }} />
              </IconButton>
              <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
              >
                <Typography className={classes.typography}>
                  <Button variant="outlined" color="secondary"
                          startIcon={<ExitToAppSharp/>}
                          onClick={onHangup}>
                    Leave Meeting
                  </Button>
                </Typography>
              </Popover>
            </Grid>
          </Grid>
      }
    </Grid>
  );
};
