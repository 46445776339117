import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {  MessageList, Message, MessageText, Row, Avatar } from '@livechat/ui-kit';
import { TextComposer, TextInput, SendButton } from '@livechat/ui-kit';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {ThemeProvider} from "@livechat/ui-kit";

const textAreaHeight = 48;

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    button: {
        marginTop: '1em'
    },
    chatList: {
        width: '100%',
        height: `calc(100% - ${textAreaHeight}px)`,
        flexShrink: 2,
        overflowY: 'auto',
        background: 'rgba(244, 244, 244, 1)'
    },
    sendArea: {
        width: '100%',
        maxHeight: `${textAreaHeight}px)`,
        background: '#eceff1',
        borderRadius: '5px 15px'
    },
    textField: {
        width: `calc(100% - 50px)`,
    },
    author: {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: '1.4em 0.3em 0.3em 1.4em',
        backgroundColor: 'rgb(66, 127, 225)',
        color: 'rgb(255, 255, 255)',
    }
});

export default function Chat(props) {
    console.log("[CHAT SCREEN] rendering....");
    const classes = useStyles();
    const [ message, setMessage ] = useState('11111111');
    const [ chats , setChats ] = useState([]);
    const { conference } = useSelector(state => state.call);
    const { conferenceMembers } = useSelector( state => state.call );
    const { user } = useSelector(state => state.user);
    const { incomingMessage } = useSelector( state => state.chat);

    const authorStyle = {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: '1.4em 0.3em 1.4em 1.4em',
        backgroundColor: 'rgb(66, 127, 225)',
        marginRight: '0.8em',
        color: 'rgb(255, 255, 255)',
    };
    const visitorStyle = {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: ' 0.3em 1.4em 1.4em 1.3em',
        backgroundColor: 'rgb(251, 251, 251)',
        marginLeft: '0.3em',
        color: 'rgb(0, 0, 0)',
    };

    const authorAvatarStyle = {
        border: '1px solid rgb(66, 127, 225)',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: 'rgb(255, 255, 255)',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        marginRight: '0.5em'
    }
    const visitorAvatarStyle = {
        border: '1px solid rgb(255, 255, 255)',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: 'rgb(66, 127, 225)',
        color: 'rgb(255, 255, 255)',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        marginRight: '0.5em'
    }

    const sendMessage = (message) => {
        if (conference && message) {
            conference.sendChat(message);
        }
    }

    useEffect( () => {
        if(incomingMessage) {
            let newChats = chats;
            newChats.push(incomingMessage);
            setChats(newChats);
            setMessage('');
        }
    },[incomingMessage]);

    useEffect( () => {
        if(!conference)
            setChats([]);
    },[conference])


    return (
        <ThemeProvider>
        <Grid container
               direction="column"
               justify="flex-end"
               alignItems="center" style={{height: '50%' }}>
             <Grid item className={classes.chatList}>
                 <MessageList active style={{background: 'rgba(244, 244, 244, 1)'}}>
                   { chats ?
                       chats.map((chat, index) => {
                           let filteredParticipant = conferenceMembers.filter(
                               member => {
                                   // console.log('userId:', member['userId'], 'display:', member['display']);
                                   return chat.from == member['userId'] || chat.from == member['display']}
                                   );
                           // make sure at least One
                           // fix for case Reload browser with different extension
                           if(filteredParticipant.length == 0) {
                               filteredParticipant.push({
                                   me: true,
                                   display: user['userInfo']['display']
                               })
                           }
                           filteredParticipant = filteredParticipant[0];

                           let isOwn = filteredParticipant['me'] ? filteredParticipant['me'] : false;
                           return (
                                <Message authorName={filteredParticipant['display']} isOwn={isOwn} key={index}>
                                   {/*<Avatar style={isOwn ? authorAvatarStyle : visitorAvatarStyle}*/}
                                   {/*         letter={ isOwn ? 'Me' : filteredParticipant['display'].substring(0, 2).toUpperCase()}*/}
                                   {/*/>*/}
                                   <MessageText style={isOwn ? authorStyle : visitorStyle}>{chat.message}</MessageText>
                               </Message>);
                       })
                       : null
                   }
                 </MessageList>
             </Grid>
             <Grid item className={classes.sendArea}>
                 <TextComposer value={`${message}`} onSend={sendMessage} >
                     <Row align="center">
                         <TextInput full='true' />
                         <SendButton fit />
                     </Row>
                 </TextComposer>
             </Grid>
         </Grid>
        </ThemeProvider>
    );
};
