import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";

import MobileConference from './mobile'
import TabletConference from './tablet'
import BrowserConference from './browser'

export default function InConference(props) {

    return(<div>
            {!isMobile ? <BrowserConference/> :
                <React.Fragment>
                    { isTablet ? <MobileConference/> : <MobileConference/> }
                </React.Fragment>
            }
        </div>)
}