import React from 'react';
import {Backdrop, Typography, Fab } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));

export default function Loading(props) {
    const { open }  = props;

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Backdrop className={classes.backdrop} open={open}>
                <Fab
                    aria-label="save"
                    color="primary"
                    style={{position:'absolute'}}
                    className={classes.buttonSuccess}
                >
                    <CallIcon/>
                </Fab>
                <CircularProgress color="inherit" size={68}/>
                {/*<Typography variant="h6" color="textPrimaryColor">Loading</Typography>*/}
            </Backdrop>
        </div>
    );
}