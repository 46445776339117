import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {  MessageList, Message, MessageText, Row, Avatar } from '@livechat/ui-kit';
import { TextComposer, TextInput, SendButton } from '@livechat/ui-kit';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {ThemeProvider} from "@livechat/ui-kit";

const textAreaHeight = 48;

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    // author: {
    //     border: '1px solid rgba(0, 0, 0, 0.05)',
    //     borderRadius: '1.4em 0.3em 0.3em 1.4em',
    //     backgroundColor: 'rgb(66, 127, 225)',
    //     color: 'rgb(255, 255, 255)',
    // }
});

export default function Chat(props) {
    console.log("[CHAT SCREEN] rendering....");
    const classes = useStyles();
    const [ message, setMessage ] = useState('11111111');

    const chatSample = [
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Pham Duc Toan", message: "I'm here" },
        { from: "Bao Pham", message: "Let's go have lunch" },
    ];
    const [ chats , setChats ] = useState(chatSample);
    const { conference } = useSelector(state => state.call);
    const { conferenceMembers } = useSelector( state => state.call );
    const { user } = useSelector(state => state.user);
    const { incomingMessage } = useSelector( state => state.chat);

    const authorStyle = {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: '1.4em 0.3em 1.4em 1.4em',
        backgroundColor: 'rgb(66, 127, 225)',
        marginRight: '0.8em',
        color: 'rgb(255, 255, 255)',
    };
    const visitorStyle = {
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: ' 0.3em 1.4em 1.4em 1.3em',
        backgroundColor: 'rgb(251, 251, 251)',
        marginLeft: '0.3em',
        color: 'rgb(0, 0, 0)',
    };
   const sendMessage = (message) => {
        if (conference && message) {
            conference.sendChat(message);
        }
    }

    useEffect( () => {
        if(incomingMessage) {
            let newChats = chats;
            newChats.push(incomingMessage);
            setChats(newChats);
            setMessage('');
        }
    },[incomingMessage]);

    // useEffect( () => {
    //     if(!conference)
    //         setChats([]);
    // },[conference])


    return (
        <ThemeProvider>
            <Grid container
                  direction="column"
                  justify="flex-end"
                  alignItems="stretch" style={{height: '100%',width:'inherit', maxWidth:"100%" }}>
               <MessageList active
                   style={{ backgroundColor:"transparent", color: "rgba(255,255,255,1)", paddingBottom:"48px"}}
                       >
                   {   !chats ? null :
                        chats.map((chat, index) => {
                            // // let filteredParticipant = conferenceMembers.filter(
                            // // member => {
                            // //     return chat.from == member['userId'] || chat.from == member['display']}
                            // // );
                            // // // make sure at least One
                            // // // fix for case Reload browser with different extension
                            // // if(filteredParticipant.length == 0) {
                            // //     filteredParticipant.push({
                            // //         me: true,
                            // //         display: user['userInfo']['display']
                            // //     })
                            // // }
                            // // filteredParticipant = filteredParticipant[0];
                            // // let isOwn = filteredParticipant['me'] ? filteredParticipant['me'] : false;
                            // return (
                            //     <Message authorName={filteredParticipant['display']} isOwn={isOwn} key={index}>
                            //         <MessageText style={isOwn ? authorStyle : visitorStyle}>{chat.message}</MessageText>
                            //     </Message>);

                            let isOwn = Math.random() <= 0.5 ? false : true;
                            return (
                                <Message authorName={chat.from} isOwn={isOwn} key={index}>
                                    <MessageText style={isOwn ? authorStyle : visitorStyle}>{chat.message}</MessageText>
                                </Message>);
                        })
                   }
               </MessageList>
               {/*<div style={{height: "48px"}}></div>*/}
               <TextComposer value={`${message}`} onSend={sendMessage}
                style={{position:'fixed', bottom: 0, left: 0, right: 0, maxWidth:"100%"}}
               >
                    <Row align="center">
                        <TextInput full='true' />
                        <SendButton fit />
                    </Row>
               </TextComposer>
            </Grid>
        </ThemeProvider>
    )
};
