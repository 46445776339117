import {
  NEW_CALL,
  INCOMING_CALL,
  END_CALL,
  LOCAL_STREAM, LOGOUT,
  REMOTE_STREAM,

  UPDATE_CALL_STATE,
  JOIN_CONFERENCE,  LEAVE_CONFERENCE,
  CONFERENCE_CHAT_MESSAGE,
  JOIN_CONFERENCE_MEMBER, UPDATE_CONFERENCE_MEMBER, LEFT_CONFERENCE_MEMBER, CLEAR_CONFERENCE_MEMBER,
} from '../actions/actionTypes';

const defaultState = {
  call: null,
  useVideo: null,
  useAudio: null,
  incomingCall: null,
  callState: null,
  localStream: null,
  remoteStream: null,
  conference: null,
  conferenceState: null,
  conferenceMembers: [],
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case INCOMING_CALL: {
      return {
        ...state,
        incomingCall: action.payload.incomingCall
      }
    }
    case NEW_CALL: {
      console.log('[New Call]:',action.payload.call);
      return {
        ...state,
        call: action.payload.call,
        // useVideo: action.payload.call.options.useVideo != false ? true : false,
        // useAudio: action.payload.call.options.useAudio != false ? true : false,
      }
    }
    case END_CALL: {
      return {
        ...state,
        call: null
      }
    }
    case LOCAL_STREAM: {
      return {
        ...state,
        localStream: action.payload.localStream
      }
    }
    case REMOTE_STREAM: {
      return {
        ...state,
        remoteStream: action.payload.remoteStream
      }
    }
    case UPDATE_CALL_STATE: {
      return {
        ...state,
        callState: action.payload.callState
      }
    }
    case JOIN_CONFERENCE: {
      return {
        ...state,
        conference: action.payload.conference,
        conferenceMembers: action.payload.members,
        conferenceState: JOIN_CONFERENCE,
      }
    }
    case LEAVE_CONFERENCE: {
      return {
        ...state,
        conference: null,
        conferenceMembers: [],
        conferenceState: LEAVE_CONFERENCE,
      }
    }
    case JOIN_CONFERENCE_MEMBER: {
      console.log('[callReducer] JOIN_CONFERENCE_MEMBER');
      return {
        ...state,
        conferenceMembers: action.payload.members,
        conferenceState: JOIN_CONFERENCE_MEMBER
      }
    }
    case LEFT_CONFERENCE_MEMBER: {
      console.log('[callReducer] LEFT_CONFERENCE_MEMBER');
      return {
        ...state,
        conferenceMembers: action.payload.members,
        conferenceState: LEFT_CONFERENCE_MEMBER
      }
    }
    // case UPDATE_CONFERENCE_MEMBER: {
    //   console.log('[callReducer] UPDATE_CONFERENCE_MEMBER');
    //   return {
    //     ...state,
    //     conferenceMembers: action.payload.members,
    //     conferenceState: UPDATE_CONFERENCE_MEMBER
    //   }
    // }
    case CLEAR_CONFERENCE_MEMBER: {
      console.log('[callReducer] CLEAR_CONFERENCE_MEMBER');
      return {
        ...state,
        conferenceMembers: action.payload.members,
        conferenceState: CLEAR_CONFERENCE_MEMBER
      }
    }
    case LOGOUT: {
      return defaultState;
    }
    default:
      return state;

  }
}
