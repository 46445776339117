import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar, IconButton, Typography, Button } from "@material-ui/core";
import {Menu} from "@material-ui/icons";
import NewJoin from './join/index'
import Join from "./Join"
import InConference from "./inconference";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));



export default function App() {
    const classes = useStyles();

    return (
        <Router>
            <div>
                {/*<nav>*/}
                {/*    <AppBar position="static" style={{color: 'rgb(66, 127, 225)', background: 'rgb(255,255,255', height: '80px'}}>*/}
                {/*        <Toolbar>*/}

                {/*            <Typography variant="h6" className={classes.title}>*/}
                {/*                Callinapp*/}
                {/*            </Typography>*/}
                {/*            <Button color="inherit">Language</Button> |*/}
                {/*            <Button color="inherit">Setting</Button>*/}
                {/*        </Toolbar>*/}
                {/*    </AppBar>*/}
                {/*</nav>*/}
                {/*<div style={{height: '10px' }} />*/}

                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/j/:roomId" children={<Join />} />
                    <Route path="/sub/join">
                        <NewJoin />
                    </Route>
                    <Route path="/sub/inconference">
                        <InConference />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function Home() {
    return <h2>Home</h2>;
}

function Users() {
    return <h2>Users</h2>;
}