import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import './index.css';
import AppRouter from './Router';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

console.log("Theme:", theme);

ReactDOM.render(
  <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
