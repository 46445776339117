import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation  } from "react-router-dom";
import CallInAppService from '../call-in-app/CallInAppService';
import {AppBar, Paper, Toolbar, IconButton, Typography, Button, Grid, Box, Link} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import {makeStyles} from "@material-ui/core/styles";

import { Loading } from '../views'

export default function BrowserConference (props) {
    return(<div>
        This is InConferece For Browser
    </div>)
}