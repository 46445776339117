import callReducer from './call-in-app/reducers/callReducer';
import userReducer from './call-in-app/reducers/userReducer';
import { combineReducers } from 'redux';
import conferenceReducer from "./call-in-app/reducers/conferenceReducer";
import timeReducer from "./call-in-app/reducers/timeReducer";
import chatReducer from "./call-in-app/reducers/chatReducer";

const combinedReducer = combineReducers({
  call: callReducer,
  user: userReducer,
  conference: conferenceReducer,
  time: timeReducer,
  chat: chatReducer
});
export default combinedReducer;
