import {
    CONFERENCE_CHAT_MESSAGE,
} from '../actions/actionTypes';

const defaultState = {
    incomingMessage: null
};
export default (state = defaultState, action) => {
    switch (action.type) {
        case CONFERENCE_CHAT_MESSAGE: {
            return {
                ... state,
                incomingMessage: action.payload.incomingMessage,

            }
        }
        default:
            return state;

    }
}
