import {
    TIME_ELAPSED,
} from '../actions/actionTypes';

const defaultState = {
    timeElapsed: null,
};
export default (state = defaultState, action) => {
    switch (action.type) {
        case TIME_ELAPSED: {
          return {
            ...state,
            timeElapsed: action.payload.timeElapsed
          }
        }
        default:
            return state;
    }
}
