import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import {Box, Button, Grid, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  button: {
  marginTop: '1em'
}
});


export default function Login(props) {
  const classes = useStyles();
  const { handleLogin, user } = props;

  const [wssUrl, setWssUrl] = useState(user ? user.wssUrl : '');
  const [extension, setExtension] = useState(user? user.extension : '');
  const [domain, setDomain] = useState(user ? user.domain : '');
  const [password, setPassword] = useState(user ? user.password: '');
  const [callerIdName, setCallerIdName] = useState((user && user.callOptions) ? user.callOptions.callerIdName : '');
  const [callerIdNumber, setCallerIdNumber] = useState((user && user.callOptions) ? user.callOptions.callerIdNumber: '');
  const [email, setEmail] = useState((user && user.userInfo) ? user.userInfo.email : '');

  const onSubmit = (evt => {
    evt.preventDefault();

    const user = {
      wssUrl,
      extension,
      domain,
      password,
      callOptions: {
        callerIdName,
        callerIdNumber,
      },
      userInfo: {
        email
      }
    };

    handleLogin(user);
  });

  return (
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
          <Grid item xs={12}>
            <TextField className={classes.root} placeholder="wss://domain.com:8082" label="WSS URL"
                       value={wssUrl} onChange={e => setWssUrl(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField className={classes.root} placeholder="1234" label="Extension"
                       value={extension} onChange={e => setExtension(e.target.value)} />
          </Grid>
         <Grid item xs={12}>
           <TextField className={classes.root} placeholder="domain.com" label="Domain"
                      value={domain} onChange={e => setDomain(e.target.value)} />
         </Grid>
         <Grid item xs={12}>
           <TextField className={classes.root} placeholder="Password" label="Password"
                      value={password} onChange={e => setPassword(e.target.value)}/>
         </Grid>
         <Grid item xs={12}>
           <TextField className={classes.root} placeholder="Caller ID Name" label="Caller ID Name"
                      value={callerIdName} onChange={e => setCallerIdName(e.target.value)}/>
         </Grid>
         <Grid item xs={12}>
           <TextField className={classes.root} placeholder="Caller ID Number" label="Caller ID Number"
                      value={callerIdNumber} onChange={e => setCallerIdNumber(e.target.value)}/>
         </Grid>
          <Grid item xs={12}>
            <TextField className={classes.root} placeholder="Your email" label="Email"
                       value={email} onChange={e => setEmail(e.target.value)} />
          </Grid>
          <Button className={classes.button} variant="outlined" color="primary" type="submit">
              Login
           </Button>
        </Grid>
        <Grid item xs={12}>
          <h3>
            For an example account, please email to <a href="mailto:hoan@callinapp.com?subject=[CallInApp]%Example%20Account%20Request">Call In App Team</a>
          </h3>
        </Grid>
      </form>
  )
};
