import React, { useState, useEffect } from 'react';
import {Button, Container, Grid, TextField} from '@material-ui/core';
import ListItem from "@material-ui/core/ListItem";
import {MicOff, Mic } from '@material-ui/icons';
import {VideocamOff, Videocam } from '@material-ui/icons';
import List from "@material-ui/core/List";
import { Avatar, Column, Row, Title, Subtitle } from "@livechat/ui-kit";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";



const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    button: {
        marginTop: '1em'
    }
});

export default function Participants(props) {
    const classes = useStyles();
    const [participants, setParticipants] = useState([]);
    const { conferenceMembers } = useSelector( state => state.call );
    const { updatedMembers } = useSelector( state => state.conference );

    const avatarStyle = {
        // border: '1px solid #1e88e5',
        border: '1px solid rgb(255, 255, 255)',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: 'rgb(255, 255, 255)',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        marginRight: '0.5em'
    };
    const talkingStyle = {
        // border: '1px solid #aed581',
        border: '1px solid rgb(255, 255, 255)',
        // boxShadow: '0 0 0 3px #aed581',
        boxShadow: '0px 0px 20px -3px black',
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: 'rgb(255, 255, 255)',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        marginRight: '0.5em'
    }

    useEffect( () => {
        // console.log("[Call] Conference Members update....",conferenceMembers);
        setParticipants(conferenceMembers);
    },[conferenceMembers]);

    useEffect( () => {
        console.log("[Conference] Conference Members update....",updatedMembers);
        setParticipants(updatedMembers);
    },[updatedMembers]);

    return ( <div style={{height: '50%', background: 'rgb(244, 244, 244)'}}>
            {! participants ? null :
                <List>
                    {participants.map((participant, index) => {
                        let isTalking = participant['media']['audio']['talking'];
                        let display = participant['display'] || "sharing screen ...";


                        return (<ListItem button key={participant.id}>
                            <Avatar style={isTalking ? talkingStyle : avatarStyle}
                                    letter={String(participant['display']).substring(0, 2).toUpperCase()}/>
                            <Column>
                                <Row justify>
                                    {!participant.me ? <Title ellipsis>{display}</Title>
                                        : <Title ellipsis>{`${display} (Me)`}</Title> }
                                </Row>
                                <Subtitle ellipsis>
                                    {participant.email}
                                </Subtitle>
                            </Column>
                            <Column fill>
                                <Row justify>
                                    {participant['media']['audio']['muted']
                                        ? <MicOff color="secondary" style={{margin: "0em 0.5em"}}/>
                                        : <Mic color="secondary" style={{margin: "0em 0.5em"}}/>}
                                    {participant['media']['video']['muted'] ?
                                        <VideocamOff color="secondary"/> : <Videocam color="secondary"/>}
                                    {/*<Title ellipsis>{participant.callerIdName}</Title>*/}
                                    {/*<Subtitle nowrap>{'14:31 PM'}</Subtitle>*/}
                                </Row>
                            </Column>
                        </ListItem>);
                    })}
                </List>
            }
        </div>



    );
};
