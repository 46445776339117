import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Button,Link, Container, Grid, TextField} from '@material-ui/core';
import {Paper, Box, Typography, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar} from "@material-ui/core";
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles} from "@material-ui/core/styles";
import { blue } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    button: {
        marginTop: '1em'
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const _savedConferenceProfile = '_savedConferenceProfile';
const conferenceTypes = [
    {'type': 'audio', 'title': 'Join Audio Conference', 'useAudio':true, 'useVideo': false},
    {'type': 'video', 'title': 'Join Video Conference', 'useAudio':true, 'useVideo': true}];


export default function Launch(props) {
    const classes = useStyles();
    const { handleMakeCall } = props;
    const [confDestination, setConfDestination] = useState(null);
    const [conferenceProfile, setConferenceProfile] = useState(null);
    useEffect( () => {
        let savedConferenceProfile = localStorage.getItem(_savedConferenceProfile);
        savedConferenceProfile = JSON.parse(savedConferenceProfile);

        let confDestination = savedConferenceProfile['confDestination'];
        setConferenceProfile(savedConferenceProfile);
        setConfDestination(confDestination);
    },[])

    const onLaunchMeeting = (isVideoConference = false) => {
        if (confDestination) {
            handleMakeCall(confDestination, isVideoConference)
        }
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        if(value) {
            switch (value) {
                case 'audio': onLaunchMeeting(false); break;
                case 'video': onLaunchMeeting(true); break;
                case 'setDevices': break;
            }
        }
    };

    return (
            <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            {/*<Button className={classes.button} variant="contained" color="primary" onClick={onLaunchMeeting}>Launch Meeting</Button>*/}
                <Button className={classes.button} variant="contained" color="primary" onClick={handleClickOpen}>Launch Meeting</Button>
                <SimpleDialog open={open} onClose={handleClose} />
                <div style={{height: '40px'}}></div>

                { conferenceProfile ? <ConferencePaper profile={conferenceProfile}/> : null }
                { conferenceProfile ? <HostLogin/> : null }
                {/*<CopyRight/>*/}
            </Grid>
        );
};

function HostLogin(){
    return (
        <div style={{textAlign: 'center'}}>
            <div style={{height: '20px'}}></div>
            <Typography variant='body2' component="div">
                If you are the conference host,
            </Typography>
            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    console.info("I'm going to login as a Host.");
                }}
            >
                Host Login?
            </Link>
        </div>
    );
}

function CopyRight() {
    return (
        <div style={{position:'absolute', bottom: 0, color: grey[500]}}>
            <Typography variant='body2' component='div'>
                Copyright ©2020 Callinapp Conference, Inc. All rights reserved.
            </Typography>
            <Typography variant='body2' component="div" style={{textAlign: 'center'}}>
                Privacy & Legal Policies
            </Typography>
        </div>
    );
}

function ConferencePaper (props) {
    const classes = useStyles();
    const { profile } = props;
    return (
        <Paper elevation={3} style={{minWidth: '300px'}}>
            <List>
                <ListItem>
                    <Typography variant='body2'>Conference Name:</Typography>
                    <Typography variant='h6'  noWrap>
                        Click & Conference 
                        {/* {profile.confName || ''} */}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant='body2'>Brief Description:</Typography>
                    <Typography variant='h6' color="textPrimary" noWrap>
                        {profile.confBrief || 'built from Callinapp SDK'}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant='body2'>Hosted By:</Typography>
                    <Typography variant='h6' color="textPrimary" noWrap>
                        {profile.hostedBy || 'Admin'}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant='body2'>Date/Time:</Typography>
                    <Typography variant='h6' color="textPrimary" noWrap>
                        {profile.dateTime || new Date(Date.now()).toUTCString()}
                    </Typography>
                </ListItem>
            </List>
        </Paper>
    );
}

function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Chose Conference Type</DialogTitle>
            <List>
                {conferenceTypes.map((confType) => (
                    <ListItem button onClick={() => handleListItemClick(confType.type)} key={confType.title}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                { confType.type == 'audio' ? <MicIcon/> : <VideocamIcon/> }
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={confType.title} />
                    </ListItem>
                ))}

                <ListItem autoFocus button onClick={() => handleListItemClick('setDevices')}>
                    <ListItemAvatar>
                        <Avatar>
                            <SettingsIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Device Settings" />
                </ListItem>
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
};